import _ from 'lodash';
import md5 from 'md5';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { APP_IMG, SHOW_COMMEMORATE } from 'src/constants';

const SectionLandingPage = ({ data = [] }: any) => {
  if (_.isEmpty(data)) return null;

  const router: any = useRouter();
  const [visible, setVisible] = useState(false);

  function openCookieAccept() {
    const cookieFirstPage = Cookies.get('first_page');
    if (cookieFirstPage) {
      document.getElementById('landing-page')?.remove();
    }
    setVisible(!cookieFirstPage);
  }

  async function handleAcceptDetail() {
    const _id = md5('first_page');
    const intenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

    Cookies.set('first_page', _id, { expires: intenMinutes });
    // router.push('/bcgtalk2022');
  }
  async function handleAccept() {
    const _id = md5('first_page');
    const intenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

    Cookies.set('first_page', _id, { expires: intenMinutes });
    router.reload(window.location.pathname);
  }

  useEffect(() => {
    openCookieAccept();
  }, [visible]);

  useEffect(() => {
    // handleCommemorate
    const getBody: any = document.querySelector('body');
    // handleCommemorate
    if (visible) {
      // handleCommemorate
      getBody.style.overflow = 'hidden';
      // handleCommemorate
      document.getElementById('__next')?.classList?.add('show-landing-page');
      document.getElementById('truehits_div')?.setAttribute('style', 'display:none;');
    } else {
      getBody.style.overflow = 'initial';
      document.getElementById('__next')?.classList?.remove('show-landing-page');
      document.getElementById('truehits_div')?.setAttribute('style', 'display:flex;');
    }
  }, [visible]);

  return (
    <>
      <SectionWrapper
        id='landing-page'
        className='landing-wrapper'
        style={{
          filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset'
        }}
        dataImage={{ ...data }}
      >
        <div className='landing-banner'>
          <div className='landing-item container'>
            <img src={`${APP_IMG}/${data?.imagePath}`} alt={`${data?.title}`} width='100%' height='100%' className='desktop-images' />
            <img src={`${APP_IMG}/${data?.imagePathMobile}`} alt={`${data?.title}`} width='100%' height='100%' className='mobile-images' />
            <div className='footer-landing'>
              {/* {data.url.includes('awards') && new Date() < new Date('2024-03-16') && (
                <button id='btn-inside' aria-label='inside' title='inside' onClick={() => handleAcceptDetail()}>
                  <a href={'https://awards.thepeople.co/vote'}>
                    {' '}
                    <span>โหวต</span>{' '}
                  </a>
                </button>
              )} */}
              {data.url && (
                <button id='btn-inside' aria-label='inside' title='inside' onClick={() => handleAcceptDetail()}>
                  <a href={data.url}>
                    {' '}
                    <span>ดูรายละเอียด</span>{' '}
                  </a>
                </button>
              )}
              <button id='btn-inside' aria-label='inside' title='inside' onClick={() => handleAccept()}>
                <span>
                  {' '}
                  เข้าสู่เว็บไซต์ <br />
                  The People{' '}
                </span>
              </button>
            </div>
          </div>
        </div>
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.section<{ dataImage: any }>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999999999;
  /* background-image: ${({ dataImage }) => `url(${APP_IMG}/${dataImage?.imagePath})`};
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat; */
  background-size: contain;
  background-color: rgba(256, 256, 256, 1);
  .landing-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 1200px) {
      left: 0;
      transform: translate(0, -50%);
    }
    @media (max-width: 690px) {
      margin-bottom: 30px;
      top: 0%;
      left: 0%;
      right: 0;
      transform: translate(0, 0%);
    }
    @media (min-width: 1720px) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .landing-item {
      display: block;
      width: 1300px;
      @media (max-width: 1200px) {
        width: 100%;
      }
      @media (max-width: 690px) {
        width: 600px;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      &.desktop-images {
        display: block;
        @media (max-width: 690px) {
          display: none;
        }
      }
      &.mobile-images {
        display: none;
        @media (max-width: 690px) {
          display: block;
        }
      }
    }
  }
  .footer-landing {
    position: absolute;
    bottom: 10%;
    left: 50%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: translate(-50%, -10%);
    @media (max-width: 475px) {
      bottom: 25%;
    }
    /* @media (max-width: 768px) {
      margin: 60px 0 30px 0;
    } */
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 50px;
    /* max-width: 200px; */
    /* margin: 20px 30px 20px auto; */
    padding: 5px 10px;
    cursor: pointer !important;
    background: #fff;
    color: #ed1a3b;
    border: none;
    border-radius: 50px;
    box-shadow: 0px 2px 3px #c3c3c3;
    font-family: 'Prompt', sans-serif;
    @media (max-width: 1024px) {
      width: 130px;
      height: 35px;
      font-size: 14px;
      line-height: 1;
    }

    span {
      color: #ed1a3b;
      font-size: 14px;
      @media (max-width: 475px) {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 767px) {
    /* background-image: ${({ dataImage }) => `url(${APP_IMG}/${dataImage?.imagePathMobile})`};
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; */
    .footer-landing {
      justify-content: center;
    }
  }

  @media (max-width: 475px) {
    button {
      max-width: 150px;
      font-size: 16px;
      /* margin: 20px auto 20px auto; */
    }
  }
`;

export default SectionLandingPage;
